import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { EnvelopeFill } from 'react-bootstrap-icons';
import { login, resendConfirmationEmail } from '../../services/api';
import { jwtDecode } from 'jwt-decode';
import './login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [resendCooldown, setResendCooldown] = useState(0);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await login({ email, password });
            const { token } = response.data;
            localStorage.setItem('token', token);
            const user = jwtDecode(token);
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/');
            window.location.reload();
        } catch (error) {
            if (error.response) {
                const errorMsg = error.response.data.message;
                if (errorMsg === "Vui lòng xác nhận email để đăng nhập.") {
                    setShowModal(true);
                    setModalMessage("Email của bạn chưa được xác nhận. Vui lòng kiểm tra hộp thư hoặc bấm nút dưới đây để gửi lại email xác nhận.");
                } else if (errorMsg === "Tài khoản không tồn tại. Vui lòng đăng ký.") {
                    setError("Tài khoản không tồn tại. Vui lòng đăng ký.");
                } else if (errorMsg === "Sai email hoặc mật khẩu.") {
                    setError("Sai email hoặc mật khẩu.");
                } else {
                    setError("Có lỗi xảy ra. Vui lòng thử lại.");
                }
            } else {
                setError("Có lỗi xảy ra. Vui lòng thử lại.");
            }
        }
    };

    const handleResendEmail = async () => {
        try {
            await resendConfirmationEmail(email);
            setModalMessage('Email xác nhận đã được gửi lại. Vui lòng kiểm tra hộp thư của bạn. Nếu không thấy, vui lòng kiểm tra thư mục thư rác (Spam).');
            setResendCooldown(90); // Bắt đầu đếm ngược 90 giây

            const interval = setInterval(() => {
                setResendCooldown(prev => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (error) {
            console.error('Lỗi khi gửi lại email xác nhận:', error);
            setModalMessage('Lỗi khi gửi lại email xác nhận. Vui lòng thử lại sau.');
        }
    };

    return (
        <Container fluid className="d-flex mt-5 justify-content-center min-vh-100">
            <Row className="justify-content-md-center w-100">
                <Col md="6" lg="4">
                    <Card className="p-4 shadow-lg login-card">
                        <h2 className="text-center mb-4 login-title">Đăng Nhập</h2>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formEmail" className="mb-3">
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Nhập email"
                                    required
                                    className="rounded-pill"
                                />
                            </Form.Group>
                            <Form.Group controlId="formPassword" className="mb-3">
                                <Form.Control
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Nhập mật khẩu"
                                    required
                                    className="rounded-pill"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="w-100 rounded-pill login-btn mt-3">
                                Đăng Nhập
                            </Button>
                        </Form>
                        <div className="d-flex justify-content-between mt-3">
                            <Link to="/find-password" className="text-decoration-none">
                                Quên mật khẩu?
                            </Link>
                            <Link to="/register" className="text-decoration-none">
                                Đăng ký
                            </Link>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                centered
                className="fade-modal"
            >
                <Modal.Header className="border-0 pb-0">
                    <Modal.Title className="w-100 text-center">
                        <h4 className="modal-title">Xác nhận Email</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center px-4 pt-3">
                <EnvelopeFill className="text-primary mb-3" size={50} />
                    <p className="modal-message mb-4">{modalMessage}</p>

                    <div className="d-grid gap-3">
                        <Button
                            variant={resendCooldown > 0 ? "secondary" : "primary"}
                            onClick={handleResendEmail}
                            className="modal-button"
                            disabled={resendCooldown > 0}
                        >
                            {resendCooldown > 0 ? (
                                <span className="countdown">
                                    Vui lòng đợi {resendCooldown}s
                                </span>
                            ) : (
                                <>
                                    <EnvelopeFill className="me-2" />
                                    Gửi lại email xác nhận
                                </>
                            )}
                        </Button>
                        <Button
                            variant="light"
                            onClick={() => setShowModal(false)}
                            className="modal-button-secondary"
                        >
                            Đóng
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default Login;
