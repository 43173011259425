import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { resetPassword } from '../../services/api';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [passwordError, setPasswordError] = useState(null); 
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track button status
    const { token } = useParams();
    const navigate = useNavigate();

    // Kiểm tra độ mạnh của mật khẩu (ít nhất 8 ký tự)
    const validatePassword = (password) => {
        if (password.length < 8) {
            setPasswordError('Mật khẩu phải có ít nhất 8 ký tự.');
            return false;
        }
        setPasswordError(null);
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Kiểm tra nếu mật khẩu không khớp
        if (password !== confirmPassword) {
            setErrorMessage('Mật khẩu không khớp.');
            return;
        }

        // Kiểm tra độ mạnh của mật khẩu
        if (!validatePassword(password)) {
            setErrorMessage(null);
            return;
        }

        setIsSubmitting(true); // Disable the button and show spinner
        try {
            await resetPassword(token, { password });
            setSuccessMessage('Mật khẩu đã được cập nhật.');
            setErrorMessage(null);
            setTimeout(() => navigate('/login'));
        } catch (error) {
            setErrorMessage('Có lỗi xảy ra. Vui lòng thử lại.');
            setSuccessMessage(null);
        } finally {
            setIsSubmitting(false); // Re-enable the button
        }
    };

    return (
        <Container className="min-vh-100 d-flex justify-content-center" style={{marginTop : '8rem'}}>
            <Row className="w-100">
                <Col md="6" lg="4" className="mx-auto">
                    <h2 className="text-center mb-4">Đặt lại mật khẩu</h2>
                    {successMessage && <Alert variant="success">{successMessage}</Alert>}
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    {passwordError && <Alert variant="danger">{passwordError}</Alert>} 
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formPassword" className="mb-3">
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Nhập mật khẩu mới"
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formConfirmPassword" className="mb-3">
                            <Form.Control
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Xác nhận mật khẩu"
                                required
                            />
                        </Form.Group>

                        <Button 
                            variant="primary" 
                            type="submit" 
                            className="w-100" 
                            disabled={isSubmitting} // Disable button when submitting
                        >
                            {isSubmitting ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span className="ms-2">Đang xử lý...</span>
                                </>
                            ) : (
                                "Đặt lại mật khẩu"
                            )}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default ResetPassword;
